export default [
  {
    path: '/user/',
    name: 'user',
    component: () => import('../views/UserView.vue'),
    redirect: { name: 'user-welcome' },
    children: [
      {
        path: 'welcome',
        name: 'user-welcome',
        component: () => import('../views/UserMyDataFormView.vue'),
        meta: {
          title: 'Meus dados',
        },
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('../views/LoginUserFormView.vue'),
        meta: {
          layout: 'EmptyLayout',
        },
      },
      {
        path: 'add',
        name: 'UserAdd',
        component: () => import('../views/CreateUserFormView.vue'),
        meta: {
          layout: 'EmptyLayout',
          title: 'Criar conta',
        },
      },
      {
        path: 'forgot',
        name: 'UserPasswordFogot',
        component: () => import('../views/UserPasswordFogotFormView.vue'),
        meta: {
          layout: 'EmptyLayout',
          title: 'Redefinir senha',
        },
      },
      {
        path: 'security',
        name: 'UserSecurity',
        component: () => import('../views/UserSecurityView.vue'),
        meta: {
          title: 'Painel de segurança',
        },
      },
      {
        path: 'approval',
        name: 'UserApproval',
        component: () => import('../views/UserApprovalView.vue'),
        meta: {
          title: 'Situação cadastral',
        },
      },
    ],
  },
  {
    path: '/approval/email/verify/:key',
    name: 'UserApprovalEmailVerify',
    component: () => import('../views/UserApprovalEmailVerifyView.vue'),
    meta: {
      layout: 'EmptyLayout',
      title: 'Verificação de e-mail',
    },
  },
  {
    path: '/password/reset/:key',
    name: 'password-reset',
    component: () => import('../views/PasswordResetFormView.vue'),
    meta: {
      layout: 'EmptyLayout',
      title: 'Redefinir senha',
    },
  },
];
