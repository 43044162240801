export default [
  {
    path: '/home/',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    redirect: { name: 'home-welcome' },
    children: [
      {
        path: 'welcome',
        name: 'home-welcome',
        component: () => import('@/views/IndexView.vue'),
        meta: {
          layout: 'HomeLayout',
          title: 'VR Pay',
        },
      },
      {
        path: 'solucoes',
        name: 'solucoes-home',
        component: () => import('../views/SolucoesView.vue'),
        meta: {
          layout: 'HomeLayout',
          title: 'Soluções',
        },
      },
      {
        path: 'download',
        name: 'download-home',
        component: () => import('../views/DownloadView.vue'),
        meta: {
          layout: 'HomeLayout',
          title: 'Downloads',
        },
      },
      {
        path: 'precos',
        name: 'precos-home',
        component: () => import('../views/PrecosView.vue'),
        meta: {
          layout: 'HomeLayout',
          title: 'Preços',
        },
      },
      {
        path: 'contato',
        name: 'contato-home',
        component: () => import('../views/ContatoView.vue'),
        meta: {
          layout: 'HomeLayout',
          title: 'Contatos',
        },
      },
      {
        path: 'termos',
        name: 'termos-home',
        component: () => import('../views/TermosView.vue'),
        meta: {
          layout: 'HomeLayout',
          title: 'Termos e condições',
        },
      },
      {
        path: 'privacidade',
        name: 'privacidade-home',
        component: () => import('../views/PrivacidadeView.vue'),
        meta: {
          layout: 'HomeLayout',
          title: 'Declaração de privacidade',
        },
      },
      {
        path: 'empresa',
        name: 'empresa-home',
        component: () => import('../views/EmpresaView.vue'),
        meta: {
          layout: 'HomeLayout',
          title: 'Sobre nós',
        },
      },
    ],
  },
];
