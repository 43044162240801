import accountRouters from '@/modules/account/router';
import bankAccountRouters from '@/modules/bankAccount/router';
import checkoutRouters from '@/modules/checkout/router';
import documentAccountRouters from '@/modules/documentAccount/router';
import homeRouters from '@/modules/home/router';
import paymentRouters from '@/modules/payment/router';
import transactionsRouters from '@/modules/transactions/router';
import transfersRouters from '@/modules/transfers/router';
import userRouters from '@/modules/user/router';
import { createRouter, createWebHistory } from 'vue-router';

const baseRoutes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('@/views/IndexView.vue'),
    meta: {
      layout: 'HomeLayout',
      title: 'VR Pay',
    },
  },
  {
    path: '/welcome/',
    name: 'Welcome',
    component: () => import('../views/WelcomeView.vue'),
  },
  {
    path: '/error/',
    name: 'error',
    component: () => import('../views/ErrorView.vue'),
    meta: {
      layout: 'EmptyLayout',
      title: 'Error',
    },
  },
  {
    path: '/not-found-payment/',
    name: 'NotFoundPayment',
    component: () => import('../views/NotFoundPaymentView.vue'),
    meta: {
      layout: 'EmptyLayout',
      title: 'Pagamento não encontrado',
    },
  },
];

const routes = baseRoutes
  .concat(userRouters)
  .concat(bankAccountRouters)
  .concat(documentAccountRouters)
  .concat(checkoutRouters)
  .concat(transfersRouters)
  .concat(transactionsRouters)
  .concat(paymentRouters)
  .concat(homeRouters)
  .concat(accountRouters);

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title || to.name} - ${process.env.VUE_APP_TITLE}`;
  next();
});

export default router;
