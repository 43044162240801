export default [
  {
    path: '/account/',
    name: 'account',
    component: () => import('../views/AccountView.vue'),
    redirect: { name: 'account-welcome' },
    children: [
      {
        path: 'welcome',
        name: 'account-welcome',
        component: () => import('../views/AccountWelcome.vue'),
        meta: {
          title: 'Meus dados',
        },
      },
    ],
  },
];
