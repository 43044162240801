export default [
  {
    path: '/bank-account/',
    name: 'bank-account',
    component: () => import('../views/BankAccountView.vue'),
    redirect: { name: 'bank-welcome' },
    children: [
      {
        path: 'welcome',
        name: 'bank-welcome',
        component: () => import('../views/BankAccountWelcomeView.vue'),
        meta: {
          title: 'Dados bancários',
        },
      },
    ],
  },
];
