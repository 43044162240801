export default [
  {
    path: '/payments/',
    name: 'payments',
    component: () => import('../views/PaymentView.vue'),
    redirect: { name: 'payments-welcome' },
    children: [
      {
        path: 'welcome',
        name: 'payments-welcome',
        component: () => import('../views/PaymentWelcome.vue'),
        meta: {
          title: 'Cobranças',
        },
      },
    ],
  },
];
