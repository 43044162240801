export default [
  {
    path: '/checkout/',
    name: 'checkout',
    component: () => import('../views/CheckoutView.vue'),
    children: [
      {
        path: ':sessionId/:paymentId',
        name: 'checkout-payment',
        component: () => import('../views/CheckoutPaymentView.vue'),
        meta: {
          layout: 'EmptyLayout',
          title: 'Pagamento',
        },
      },
    ],
  },
];
