export default [
  {
    path: '/document-account/',
    name: 'document-account',
    component: () => import('../views/DocumentView.vue'),
    redirect: { name: 'document-welcome' },
    children: [
      {
        path: 'welcome',
        name: 'document-welcome',
        component: () => import('../views/DocumentWelcome.vue'),
        meta: {
          title: 'Documentos da minha conta',
        },
      },
    ],
  },
];
