<template>
  <component :is="layout">
    <slot />
  </component>
</template>

<script>
import { markRaw } from 'vue';

const defaultLayout = 'DefaultLayout';

export default {
  name: 'AppLayout',
  data: () => ({
    layout: defaultLayout,
  }),
  watch: {
    $route: {
      immediate: true,
      async handler(route) {
        try {
          if (route.name) {
            const layoutName = route.meta.layout || defaultLayout;
            const component = await import(`@/layouts/${layoutName}.vue`);

            this.layout = markRaw(component?.default);
          }
        } catch (e) {
          this.layout = defaultLayout;
        }
      },
    },
  },
};
</script>
