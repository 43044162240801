import { library } from '@fortawesome/fontawesome-svg-core';
import { faAndroid, faApple } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import dayjs from 'dayjs';
import { vMaska } from 'maska';
import money from 'v-money3';
import { createApp } from 'vue';
import VueCookies from 'vue-cookies';
import VueFuether from 'vue-feather';
import App from './App.vue';
import AppLayout from './layouts/AppLayout.vue';
import './registerServiceWorker';
import router from './router';

library.add(faApple, faAndroid);

const app = createApp(App);

app.provide('dayJS', dayjs);

app
  .use(VueCookies)
  .use(router)
  .use(money)
  .directive('maska', vMaska)
  .component(AppLayout.name, AppLayout)
  .component(VueFuether.name, VueFuether)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app');
